export const clientNameFooter = "YOGALAYA";
export const titleMain = "Yogalaya";
export const subtitleMain = "Yoga para muito além das posturas.";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "VIDA VEDA CURSOS E CERTIFICAÇÕES LTDA ME";
export const nomeFornecedor = "VIDA VEDA";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/vidaveda.yoga/",
    profile: "@vidaveda.yoga"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send?phone=5521995503234",
    number: "(21) 99550-3234"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://api.whatsapp.com/send?phone=5521995503234",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [{
  title: "Planos de Assinatura",
  link: "https://escola.yogalaya.com.br/",
}];
